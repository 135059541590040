<template>
  <div class="wrapper">
    <div class="container-fluid" v-if="loading">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <loader class="my-5" color="rgba(255, 152, 0, 0.75)"></loader>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-if="!loading">
      <div class="row">
        <div class="col">
          <div class="card-deck">
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ plannerList.length }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                Total Planners
              </div>
            </div>
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ totalPlannersMeals }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                Total Planners w/ Meals
              </div>
            </div>
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ totalPlannedMeals }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                Total Planned Meals
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card card-shadow mt-3">
            <div class="container-fluid">
              <div class="row">
                <div class="col text-left">
                  <div class="card-body">
                    <b-form inline>
                      <b-form-input
                        size="sm"
                        v-model="searchText"
                        placeholder="Search..."
                      ></b-form-input>
                    </b-form>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <b-table
                    hover
                    striped
                    responsive
                    :fields="fields"
                    :items="filteredPlanners"
                  >
                    <template #cell(actions)="data">
                      <b-button
                        variant="outline-primary"
                        size="sm"
                        @click="edit(data.item)"
                        ><i class="fas fa-edit"></i
                      ></b-button>
                    </template>
                  </b-table>
                </div>
                <div class="col profile-tab" v-if="showEdit">
                  <b-form @submit.prevent="isNew ? create() : update()">
                    <b-tabs content-class="mt-3 mx-3" class="mb-5" fill>
                      <b-tab title="Planner" active>
                        <div class="form-wrapper">
                          <div class="row settings-row">
                            <div class="col">
                              <h5>Meals</h5>
                              <table
                                id="tblSettingsMeals"
                                class="table table-striped mt-3"
                              >
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Color</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <draggable
                                  tag="tbody"
                                  handle=".handle"
                                  :list="meals"
                                  @end="updateOrder"
                                >
                                  <tr
                                    class="settings-meal-row"
                                    v-for="(meal, index) in meals"
                                    :key="index"
                                  >
                                    <td
                                      class="handle meal-handle"
                                      style="border-bottom: 0"
                                    >
                                      <i class="fas fa-grip-vertical"></i>
                                    </td>
                                    <td>
                                      <input
                                        name="name"
                                        type="text"
                                        class="form-control meal-name"
                                        placeholder="e.g. Breakfast"
                                        v-model="meal.name"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="color"
                                        type="color"
                                        class="form-control meal-color"
                                        placeholder="e.g. #52c7b8"
                                        v-model="meal.color"
                                      />
                                    </td>
                                    <td>
                                      <b-button
                                        variant="outline-danger"
                                        title="Delete"
                                        class="mx-1"
                                        @click="deleteMeal(meal)"
                                      >
                                        <i class="far fa-trash-alt"></i>
                                      </b-button>
                                    </td>
                                  </tr>
                                </draggable>
                              </table>
                              <b-button
                                variant="outline-primary"
                                size="sm"
                                class="mt-0 mb-4"
                                title="Add Meal"
                                @click="addMeal"
                              >
                                <i class="fas fa-plus"></i> Add Meal
                              </b-button>
                            </div>
                          </div>
                          <div class="row settings-row">
                            <div class="col">
                              <h5>Shopping List Automation</h5>
                              <ul class="list-group list-group-flush mt-3">
                                <li
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Add ingredients to shopping list
                                  automatically:
                                  <b-form-checkbox
                                    v-model="planner.auto_add"
                                    switch
                                  ></b-form-checkbox>
                                </li>
                                <li
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Add ingredients to list this many days in
                                  advance:
                                  <div class="form-group settings-form-group">
                                    <input
                                      id="inpAdvanceDays"
                                      name="advance_days"
                                      type="number"
                                      class="form-control"
                                      placeholder="e.g. 1"
                                      v-model="planner.advance_days"
                                    />
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                      <template #tabs-end>
                        <li
                          role="presentation"
                          class="nav-item align-self-center justify-content-end text-right"
                        >
                          <b-button
                            variant="outline-primary"
                            size="sm"
                            class="mr-1"
                            @click="openPlanner"
                          >
                            <i class="fas fa-external-link-alt"></i> Open
                          </b-button>
                          <b-button
                            type="submit"
                            variant="outline-success"
                            :disabled="saving"
                            size="sm"
                            class="mr-1"
                          >
                            <span v-if="saving">
                              <b-spinner small></b-spinner>
                              <span class="sr-only">Saving...</span>
                            </span>
                            <span v-else>
                              <i class="far fa-save"></i>
                            </span>
                          </b-button>
                          <b-button
                            variant="outline-danger"
                            @click="closeEdit"
                            size="sm"
                            class="mr-1"
                            ><i class="far fa-window-close"></i
                          ></b-button>
                        </li>
                      </template>
                    </b-tabs>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Loader from "@/components/Loader";
import draggable from "vuedraggable";
import slugify from "slugify";

export default {
  name: "Planners",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    loader: Loader,
    draggable: draggable
  },
  data() {
    return {
      bind: [
        { ref: "families", target: "families" },
        { ref: "planners", target: "planners" }
      ],
      loading: false,
      saving: false,
      planners: [],
      families: [],
      meals: [],
      mealHashes: {},
      plannerKey: null,
      planner: { meals: {} },
      newMeal: {
        name: "",
        slug: "",
        color: ""
      },
      isNew: false,
      fields: [
        {
          key: "key",
          label: "Key",
          sortable: false
        },
        {
          key: "family.name",
          label: "Family",
          sortable: true
        },
        {
          key: "advance_days",
          label: "Advance Days",
          sortable: true
        },
        {
          key: "auto_add",
          label: "Auto Add Ingredients",
          sortable: true
        },
        {
          key: "actions",
          label: "Actions"
        }
      ],
      showEdit: false,
      itemsExpanded: [],
      searchText: "",
      days: [
        { text: "Sunday", value: "sunday" },
        { text: "Monday", value: "monday" },
        { text: "Tuesday", value: "tuesday" },
        { text: "Wednesday", value: "wednesday" },
        { text: "Thursday", value: "thursday" },
        { text: "Friday", value: "friday" },
        { text: "Saturday", value: "saturday" }
      ]
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile", "config"]),
    ...mapGetters(["searchConfig"]),
    plannerList() {
      return this.planners.map(planner => {
        const familyKey = planner[".key"];
        let familyIndex = this.getIndex(this.families, familyKey);
        let family = familyIndex !== null ? this.families[familyIndex] : {};
        return {
          key: familyKey,
          family_key: familyKey,
          family: family,
          ...planner
        };
      });
    },
    filteredPlanners() {
      if (this.searchText.length > 0) {
        const searchText = this.searchText.toLowerCase();
        return this.plannerList.filter(planner => {
          let showPlanner = false;
          if (planner.family.name.toLowerCase().includes(searchText)) {
            showPlanner = true;
          }
          if ("meals" in planner) {
            planner.meals.forEach(meal => {
              if (
                "recipe" in meal &&
                String(meal.recipe.name)
                  .toLowerCase()
                  .includes(searchText)
              ) {
                showPlanner = true;
              }
            });
          }
          return showPlanner;
        });
      } else {
        return this.plannerList;
      }
    },
    totalPlannersMeals() {
      return this.planners.filter(planner => {
        return Array.isArray(planner.meals) && planner.meals.length > 0;
      }).length;
    },
    totalPlannedMeals() {
      return this.planners.reduce((total, planner) => {
        return Array.isArray(planner.meals)
          ? total + planner.meals.length
          : total;
      }, 0);
    }
  },
  watch: {
    planners: {
      immediate: true,
      handler(planners) {
        planners.forEach((planner, index) => {
          if (!Array.isArray(planner.meals)) {
            this.planners[index].meals = [];
          }
        });
      }
    }
  },
  methods: {
    edit(planner) {
      this.isNew = false;
      this.itemsExpanded = [];
      if (!Array.isArray(planner.meals)) {
        planner.meals = [];
      }
      this.plannerKey = planner.key;
      this.planner = { ...planner, meals: [...planner.meals] };
      this.bindObject("meals", this.plannerKey, "meals").then(() => {
        // Create hash for each meal to detect changes
        this.meals.forEach(async meal => {
          const hash = await this.createHash(JSON.stringify(meal));
          this.mealHashes[meal[".key"]] = hash;
        });
        // Sort meals
        this.meals.sort((a, b) => {
          a.order = "order" in a && !isNaN(a.order) ? a.order : 0;
          b.order = "order" in b && !isNaN(b.order) ? b.order : 0;
          return a.order - b.order;
        });
      });
      this.showEdit = true;
    },
    closeEdit() {
      this.planner = { meals: [] };
      this.showEdit = false;
    },
    openPlanner() {
      let url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/planners/" +
        this.plannerKey +
        "/edit";
      console.log(url);
      window.open(url);
    },
    async update() {
      this.saving = true;
      const planner = { ...this.planner };
      delete planner.key;
      delete planner.family_key;
      delete planner.family;
      if (this.plannerKey && this.plannerKey.length > 0) {
        // Compare hashes and update meals that have changed
        let promises = [];
        for (const meal of this.meals) {
          const hash = await this.createHash(JSON.stringify(meal));
          const existingHash = this.mealHashes[meal[".key"]];
          if (hash !== existingHash) {
            promises.push(this.saveMeal(meal));
          }
        }
        promises.push(this.updateObject("planners", this.plannerKey, planner));
        Promise.all(promises)
          .then(() => {
            this.saving = false;
            window.toastr.success("Planner updated successfully.");
          })
          .catch(error => {
            this.saving = false;
            window.toastr.error(
              "An error occurred updating the planner: " + error
            );
            console.error("Unable to update planner", this.plannerKey, error);
          });
      }
      return true;
    },
    getUniqueSlug(source, name) {
      let slug = slugify(name, { lower: true, strict: true });
      let modifier = 2;
      const slugs = [];
      source.forEach(item => {
        slugs.push(item.name);
      });
      while (slug in slugs) {
        modifier++;
        slug = slug + "-" + modifier;
      }
      return slug;
    },
    saveMeal(meal = null) {
      if (meal === null) {
        meal = { ...this.meal };
      }
      if (!("slug" in meal) || meal.slug === "") {
        meal.slug = this.getUniqueSlug(this.meals, meal.name);
      }
      if (".key" in meal) {
        return this.updateObject("meals/" + this.plannerKey, meal[".key"], meal)
          .then(() => {
            // Update existing planned meals
            Object.values(this.planner.meals).forEach(day => {
              console.log(day);
              Object.values(day).forEach(slug => {
                console.log(slug);
                Object.values(slug).forEach(planned => {
                  console.log(planned);
                  if (meal.slug === planned.meal.slug) {
                    planned.meal = { ...meal };
                  }
                });
              });
            });
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        return this.createObject("meals/" + this.plannerKey, meal).catch(
          error => {
            console.error(error);
          }
        );
      }
    },
    addMeal() {
      const meal = {
        name: "",
        color: "#52c7b8",
        slug: ""
      };
      this.createObject("meals/" + this.plannerKey, meal).catch(error => {
        console.log(error);
        window.toastr.error(
          "An error occurred while creating the meal. Please try again later."
        );
      });
    },
    deleteMeal(meal) {
      console.log(meal);
      this.deleteObject("meals/" + this.plannerKey, meal[".key"])
        .then(() => {
          // Remove existing planned meals
          const promises = [];
          Object.values(this.planner.meals).forEach(day => {
            console.log(day);
            Object.values(day).forEach(slug => {
              console.log(slug);
              if (slug === meal.slug) {
                promises.push(
                  this.deleteObject(
                    "planners/" + this.plannerKey + "/meals/" + day + "/" + slug
                  )
                );
              }
            });
          });
          Promise.all(promises).catch(error => {
            console.log(error);
            window.toastr.error(
              "An error occurred while deleting the meal. Please try again later."
            );
          });
        })
        .catch(error => {
          console.log(error);
          window.toastr.error(
            "An error occurred while deleting the meal. Please try again later."
          );
        });
    },
    updateOrder() {
      this.meals.forEach((meal, index) => {
        this.meals[index].order = index;
      });
    }
  },
  created() {}
};
</script>

<style scoped>
.wrapper {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.background-none {
  background: none;
}

.profile-tab {
  max-width: 600px;
}

#tblSettingsMeals {
  border: 1px solid #dee2e6;
  margin-left: auto;
  margin-right: auto;
}

.settings-row {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.settings-form-group {
  max-width: 124px;
  margin-right: 0;
  margin-bottom: 0;
}

.meal-handle {
  width: 30px;
  height: 28px;
  float: left;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
