<template>
  <div class="container-fluid">
    <div class="card card-shadow">
      <h5 class="card-header text-center">
        <b-button
          variant="secondary"
          size="sm"
          @click="showPrevWeek"
          class="mr-1"
        >
          <i class="fas fa-caret-left"></i>
        </b-button>
        {{ weekStartDate }} - {{ weekEndDate }}
        <b-button
          variant="secondary"
          size="sm"
          @click="showNextWeek"
          class="ml-1"
        >
          <i class="fas fa-caret-right"></i>
        </b-button>
      </h5>
      <div class="card-body text-center">
        <div class="card-deck">
          <div
            class="card planner-card"
            v-for="(day, index) in week"
            :key="index"
          >
            <div class="card-body">
              <h5 class="card-title">{{ getDayOfWeek(day) }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">
                {{ formatDate(day) }}
              </h6>
              <b-button
                variant="outline-primary"
                size="sm"
                title="Add Meal"
                @click="showAddMeal(day, index)"
              >
                <i class="fas fa-plus"></i> Add Meal
              </b-button>

              <b-modal
                :id="'modal_planner_add_meal_' + index"
                title="Add Meal"
                header-bg-variant="primary"
                header-text-variant="light"
                ok-title="Add Meal"
                ok-variant="primary"
                @ok="addPlannedMeal()"
              >
                <div class="form-group">
                  <label for="meal-date">Meal Date</label>
                  <b-form-datepicker
                    id="meal-date"
                    v-model="plannedMeal.date"
                    required
                  ></b-form-datepicker>
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                  <div class="help-block with-errors"></div>
                </div>
                <div class="form-group">
                  <label for="meal-select">Meal</label>
                  <b-form-select
                    id="meal-select"
                    v-model="plannedMeal.meal"
                    :options="mealOptions"
                  ></b-form-select>
                </div>
                <div class="form-group">
                  <label for="selRecipe">Recipe</label>
                  <autocomplete @change="updateRecipe"></autocomplete>
                </div>
              </b-modal>
            </div>
            <ul
              class="list-group list-group-flush"
              v-for="(meal, index) in meals"
              :key="index"
            >
              <li class="list-group-item list-group-item-primary text-center">
                <small class="text-muted">{{ meal.name }}</small>
              </li>
              <li class="list-group-item planner-list-item">
                <planner-meal
                  v-for="(planned, index) in planner.meals[
                    formatStandardDate(day)
                  ][meal.slug]"
                  :key="index"
                  :refkey="index"
                  :family-key="familyKey"
                  :planned-meal="planned"
                  :meal-date="formatStandardDate(day)"
                  :meal="meal"
                ></planner-meal>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import moment from "moment";
import PlannerMeal from "@/components/PlannerMeal";
import AlgoliaAutocomplete from "@/components/AlgoliaAutocomplete";

export default {
  name: "EditPlanner",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    autocomplete: AlgoliaAutocomplete,
    "planner-meal": PlannerMeal
  },
  data() {
    return {
      ref: "planner",
      bind: [],
      planner: {
        meals: {},
        auto_add: false,
        advance_days: 1
      },
      family: {
        shopping_day: "sunday"
      },
      days: [
        { text: "Sunday", value: "sunday" },
        { text: "Monday", value: "monday" },
        { text: "Tuesday", value: "tuesday" },
        { text: "Wednesday", value: "wednesday" },
        { text: "Thursday", value: "thursday" },
        { text: "Friday", value: "friday" },
        { text: "Saturday", value: "saturday" }
      ],
      meals: [],
      default_meals: [],
      plannedMeal: {
        date: "",
        meal: {},
        recipe: {}
      },
      weeks: [],
      week: [],
      placeholder: require("../../assets/recipe-placeholder.jpg"),
      public_recipes: [],
      family_recipes: [],
      resultLimit: 10,
      querySize: 100
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    ...mapGetters(["familyShoppingDay"]),
    familyKey() {
      return this.$route.params.id;
    },
    weekStartDate() {
      return this.formatDate(this.week[0]);
    },
    weekEndDate() {
      return this.formatDate(this.week[6]);
    },
    mealOptions() {
      return this.sourceOptions(this.meals);
    }
  },
  watch: {
    familyKey: {
      immediate: true,
      handler(family_key) {
        console.log(family_key);
        if (family_key !== null) {
          this.bindObject(
            "families/" + family_key,
            null,
            "family"
          ).then(() => {});
          this.bindObject(
            "planners/" + family_key,
            null,
            "planner"
          ).then(() => {});
          this.bindObject("meals/" + family_key, null, "meals").then(() => {
            if (this.meals.length === 0) {
              console.log(this.default_meals);
              this.default_meals.forEach(meal => {
                const newMeal = { ...meal };
                delete newMeal[".key"];
                console.log(newMeal);
                this.saveMeal(newMeal, true);
              });
            }
            // Sort meals
            this.meals.sort((a, b) => {
              a.order = "order" in a && !isNaN(a.order) ? a.order : 0;
              b.order = "order" in b && !isNaN(b.order) ? b.order : 0;
              return a.order - b.order;
            });
          });
        }
      }
    },
    planner: {
      immediate: true,
      handler(planner) {
        console.log(planner.meals);
        planner.meals = !planner.meals ? {} : planner.meals;
        this.week = this.getWeek();
      }
    },
    week: {
      immediate: true,
      handler(week) {
        week.forEach(day => {
          const date = this.formatStandardDate(day);
          this.planner.meals[date] = !this.planner.meals[date]
            ? {}
            : this.planner.meals[date];
          //this.$set(this.planner.meals, date, this.planner.meals[date]);
        });
      }
    }
  },
  methods: {
    getWeek(inputDate = null) {
      inputDate = inputDate === null ? moment() : moment(inputDate);
      let week = new Array();
      let currentDay = inputDate.startOf("week");
      do {
        const loopDay = currentDay.clone();
        //loopDay.date = currentDay.clone();
        // loopDay.text = currentDay.format("dddd");
        // loopDay.textShort = currentDay.format("ddd");
        // loopDay.us_date = currentDay.format("L");
        // loopDay.iso_date = currentDay.format();
        week.push(loopDay);
        currentDay.add(1, "days");
      } while (week.length < 7);
      return week;
    },
    formatDate(date) {
      return moment(date).format("L");
    },
    formatStandardDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getDayOfWeek(date) {
      return moment(date).format("dddd");
    },
    getDayOfWeekShort(date) {
      return moment(date).format("ddd");
    },
    prepareWeek(week, meals, calendarMeals) {
      week.forEach(day => {
        day.meals = new Array();
        meals.forEach(meal => {
          let m = new Object();
          m = { ...meal };
          m.planned = new Array();
          calendarMeals.forEach(cm => {
            cm.moment = moment(cm.meal_date);
            if (
              moment(cm.meal_date).isSame(day.iso_date, "day") &&
              meal.id === cm.meal.id
            ) {
              cm.recipe.image =
                cm.recipe.image !== null ? cm.recipe.image : this.placeholder;
              m.planned.push(cm);
            }
          });
          day.meals.push(m);
        });
      });
      return week;
    },
    showNextWeek() {
      const newWeekStart = moment(this.week[0]).add(1, "weeks");
      this.week = this.getWeek(newWeekStart);
    },
    showPrevWeek() {
      const newWeekStart = moment(this.week[0]).subtract(1, "weeks");
      this.week = this.getWeek(newWeekStart);
    },
    updateRecipe(value) {
      const recipe = { ...value };
      console.log("Recipe", recipe);
      this.plannedMeal.recipe = recipe;
    },
    showAddMeal(day, index) {
      this.plannedMeal.meal = null;
      this.plannedMeal.recipe = null;
      this.plannedMeal.date = moment(day).format("YYYY-MM-DD");
      this.$bvModal.show("modal_planner_add_meal_" + index);
    },
    addPlannedMeal() {
      const planned = { ...this.plannedMeal };
      const meal = this.meals[this.getIndex(this.meals, planned.meal)];
      planned.meal = { ...meal };

      this.createObject(
        "planners/" +
          this.familyKey +
          "/meals/" +
          planned.date +
          "/" +
          meal.slug,
        planned
      ).catch(error => {
        console.log(error);
        window.toastr.error(
          "An error occurred while updating the planner. Please try again later."
        );
      });
    },
    savePlanner(hideAlerts = false) {
      this.$nextTick().then(() => {
        const planner = { ...this.planner };
        console.log(planner);
        this.updateObject("planners/" + this.familyKey, null, planner)
          .then(() => {
            if (!hideAlerts) {
              window.toastr.success("Planner saved successfully.");
            }
          })
          .catch(error => {
            console.log(error);
            if (!hideAlerts) {
              window.toastr.error(
                "An error occurred while saving the planner. Please try again later."
              );
            }
          });
      });
    }
  },
  created() {
    this.bindObject("default_meals/", null, "default_meals").then(() => {
      if (this.meals.length === 0 && this.familyKey !== null) {
        this.default_meals.forEach(meal => {
          const newMeal = { ...meal };
          delete newMeal[".key"];
          console.log(newMeal);
          this.saveMeal(newMeal, true);
        });
      }
    });
  }
};
</script>

<style>
.planner-card {
  min-width: 150px;
  max-width: 300px;
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 5px !important;
}

.planner-list-item {
  padding-left: 2px;
  padding-right: 2px;
  text-align: center;
  min-height: 150px;
}

.planned-meal {
  min-width: 130px;
  max-width: 280px;
  height: 40px;
  margin-left: 5px !important;
  margin-right: 5px !important;
  margin-bottom: 5px !important;
}

.planned-meal .card-img {
  height: 38px;
}

.planner-recipe-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  font-size: 95%;
  font-weight: 400;
  color: white;
  padding-top: 7px;
  text-align: left;
  padding-left: 5px !important;
}

.planner-recipe-text > a {
  color: white;
}

.autocomplete-wrapper {
  width: 100%;
}

.autocomplete-input {
  width: 100%;
}

#tblSettingsMeals {
  border: 1px solid #dee2e6;
  margin-left: auto;
  margin-right: auto;
}

.settings-row {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.settings-form-group {
  max-width: 124px;
  margin-right: 0;
  margin-bottom: 0;
}

.meal-handle {
  width: 30px;
  height: 28px;
  float: left;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
