<template>
  <!-- <autocomplete
    ref="autocomplete"
    :base-class="baseClass"
    v-bind="{ ...$props, ...$attrs }"
    @submit="handleSubmit"
    v-on:input="handleInput"
  ></autocomplete> -->
  <div if="searchClient">
    <ais-instant-search
      :search-client="searchClient"
      :index-name="algoliaIndex"
    >
      <ais-autocomplete>
        <div slot-scope="{ currentRefinement, indices, refine }">
          <b-input-group>
            <b-form-input
              ref="search"
              type="search"
              class="rounded"
              :value="selected ? selected : currentRefinement"
              :placeholder="placeholder"
              @input="
                refine($event);
                showResults();
              "
            />
            <b-input-group-append>
              <b-dropdown
                toggle-class="{ d-none }"
                :menu-class="{ show: currentRefinement && showDropdown }"
                right
              >
                <div v-if="currentRefinement" :style="{ width: menuWidth }">
                  <b-dropdown-item
                    v-for="hit in indices[0].hits"
                    :key="hit.objectID"
                    @click="handleClick(hit)"
                  >
                    <ais-highlight attribute="name" :hit="hit" />
                  </b-dropdown-item>
                </div>
                <b-dropdown-header>
                  <ais-powered-by />
                </b-dropdown-header>
              </b-dropdown>
            </b-input-group-append>
          </b-input-group>
        </div>
      </ais-autocomplete>
    </ais-instant-search>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import algoliasearch from "algoliasearch/lite";

export default {
  name: "AlgoliaAutocomplete",
  props: {
    searchIndex: {
      type: String,
      required: false,
      default: "Recipes"
    },
    index: {
      type: Number,
      required: false
    },
    overwriteClass: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false,
      default: "e.g. Pizza"
    }
  },
  data() {
    return {
      searchClient: false,
      selectedValue: null,
      showDropdown: false,
      selected: "",
      menuWidth: "300px"
    };
  },
  watch: {
    searchKey: {
      immediate: true,
      handler() {
        const config = this.searchConfig;
        if (config && "app_id" in config && config.app_id !== "") {
          // Initialize Algolia
          this.searchClient = algoliasearch(config.app_id, config.search_key);
        }
      }
    }
  },
  computed: {
    ...mapGetters(["searchConfig"]),
    algoliaIndex() {
      return window.environment === "production"
        ? "prod_" + this.searchIndex
        : "sandbox_" + this.searchIndex;
    },
    searchKey() {
      return this.searchConfig.search_key;
    },
    baseClass() {
      return this.overwriteClass ? this.overwriteClass : "autocomplete";
    }
  },
  methods: {
    showResults() {
      this.showDropdown = true;
      const width = this.$refs.search.$el.offsetWidth;
      this.menuWidth = width + "px";
    },
    handleClick(selected) {
      this.showDropdown = false;
      this.selected = selected.name;
      this.$emit("change", selected);
    }
  }
};
</script>
