var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card planned-meal",style:('background-color: ' + _vm.plannedMeal.meal.color)},[_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal.modal_planner_edit_meal",modifiers:{"modal_planner_edit_meal":true}}],staticClass:"row no-gutters"},[_c('div',{staticClass:"col-4"},[_c('img',{staticClass:"card-img",attrs:{"src":_vm.image,"alt":"Recipe"}})]),_c('div',{staticClass:"col-8 planner-recipe-text"},[_c('b-link',{attrs:{"to":{
          name: 'recipe-view',
          params: { slug: _vm.plannedMeal.recipe.slug }
        }}},[_vm._v(_vm._s(_vm.plannedMeal.recipe.name))])],1)]),_c('b-modal',{attrs:{"id":"modal_planner_edit_meal","title":"Edit Meal","header-bg-variant":"primary","header-text-variant":"light","ok-title":"Add Meal","ok-variant":"primary"},on:{"ok":_vm.editPlannedMeal,"close":_vm.deletePlannedMeal,"show":_vm.setModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
        var ok = ref.ok;
        var cancel = ref.cancel;
        var close = ref.close;
return [_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":function($event){return ok()}}},[_c('i',{staticClass:"far fa-save"}),_vm._v(" Save ")]),_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":function($event){return close()}}},[_c('i',{staticClass:"far fa-trash-alt"}),_vm._v(" Delete ")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")])]}}])},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"meal-date"}},[_vm._v("Meal Date")]),_c('b-form-datepicker',{attrs:{"id":"meal-date","required":""},model:{value:(_vm.planned.date),callback:function ($$v) {_vm.$set(_vm.planned, "date", $$v)},expression:"planned.date"}}),_c('span',{staticClass:"glyphicon form-control-feedback",attrs:{"aria-hidden":"true"}}),_c('div',{staticClass:"help-block with-errors"})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"meal-select"}},[_vm._v("Meal")]),_c('b-form-select',{attrs:{"id":"meal-select","options":_vm.mealOptions},model:{value:(_vm.selectedMeal),callback:function ($$v) {_vm.selectedMeal=$$v},expression:"selectedMeal"}})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"selRecipe"}},[_vm._v("Recipe")]),_c('autocomplete',{on:{"change":_vm.updateRecipe}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }