<template>
  <div
    class="card planned-meal"
    :style="'background-color: ' + plannedMeal.meal.color"
  >
    <div class="row no-gutters" v-b-modal.modal_planner_edit_meal>
      <div class="col-4">
        <img :src="image" class="card-img" alt="Recipe" />
      </div>
      <div class="col-8 planner-recipe-text">
        <b-link
          :to="{
            name: 'recipe-view',
            params: { slug: plannedMeal.recipe.slug }
          }"
          >{{ plannedMeal.recipe.name }}</b-link
        >
      </div>
    </div>

    <b-modal
      id="modal_planner_edit_meal"
      title="Edit Meal"
      header-bg-variant="primary"
      header-text-variant="light"
      ok-title="Add Meal"
      ok-variant="primary"
      @ok="editPlannedMeal"
      @close="deletePlannedMeal"
      @show="setModal"
    >
      <template v-slot:modal-footer="{ ok, cancel, close }">
        <b-button variant="outline-success" @click="ok()">
          <i class="far fa-save"></i> Save
        </b-button>
        <b-button variant="outline-danger" @click="close()">
          <i class="far fa-trash-alt"></i> Delete
        </b-button>
        <b-button variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
      </template>
      <div class="form-group">
        <label for="meal-date">Meal Date</label>
        <b-form-datepicker
          id="meal-date"
          v-model="planned.date"
          required
        ></b-form-datepicker>
        <span class="glyphicon form-control-feedback" aria-hidden="true"></span>
        <div class="help-block with-errors"></div>
      </div>
      <div class="form-group">
        <label for="meal-select">Meal</label>
        <b-form-select
          id="meal-select"
          v-model="selectedMeal"
          :options="mealOptions"
        ></b-form-select>
      </div>
      <div class="form-group">
        <label for="selRecipe">Recipe</label>
        <autocomplete @change="updateRecipe"></autocomplete>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
//import moment from "moment";
import AlgoliaAutocomplete from "@/components/AlgoliaAutocomplete";

export default {
  name: "PlannerMeal",
  props: {
    refkey: {
      type: String,
      required: true
    },
    familyKey: {
      type: String,
      required: true
    },
    plannedMeal: {
      type: Object,
      required: true
    },
    mealDate: {
      type: String,
      required: true
    },
    meal: {
      type: Object,
      required: true
    }
  },
  mixins: [FirebaseMixin, UtilsMixin],
  components: { autocomplete: AlgoliaAutocomplete },
  data() {
    return {
      ref: "planner",
      bind: [],
      placeholder: require("@/assets/recipe-placeholder.jpg"),
      image: "",
      planned: {
        date: null,
        meal: {},
        recipe: {}
      },
      meals: [],
      selectedMeal: null,
      public_recipes: [],
      family_recipes: [],
      resultLimit: 10,
      querySize: 100
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    mealOptions() {
      return this.sourceOptions(this.meals, "slug");
    }
  },
  watch: {
    plannedMeal: {
      immediate: true,
      handler(planned) {
        this.image = this.placeholder;
        if (planned.recipe.image && planned.recipe.image.length > 0) {
          this.getImageUrl("recipes/" + planned.recipe.image).then(url => {
            this.image = url;
          });
        }
      }
    },
    familyKey: {
      immediate: true,
      handler(family_key) {
        console.log(family_key);
        if (family_key !== null) {
          this.bindObject("meals/" + family_key, null, "meals").then(() => {
            // Sort meals
            this.meals.sort((a, b) => {
              a.order = "order" in a && !isNaN(a.order) ? a.order : 0;
              b.order = "order" in b && !isNaN(b.order) ? b.order : 0;
              return a.order - b.order;
            });
          });
        }
      }
    }
  },
  methods: {
    editPlannedMeal() {
      const planned = { ...this.planned };
      planned.meal = {
        ...this.meals[this.getIndex(this.meals, this.selectedMeal, "slug")]
      };
      console.log(planned);
      // Check if date or meal have changed
      if (
        planned.date === this.mealDate &&
        planned.meal.slug === this.meal.slug
      ) {
        // Neither have changed, just save changes
        this.updateObject(
          "planners/" +
            this.familyKey +
            "/meals/" +
            this.mealDate +
            "/" +
            this.meal.slug,
          this.refkey,
          planned
        )
          .then(() => {
            window.toastr.success(
              "The planned meal has been saved successfully."
            );
          })
          .catch(error => {
            console.log(error);
            window.toastr.error(
              "An error occurred while saving the planned meal. Please try again later."
            );
          });
      } else {
        // New date/meal, need to delete and re-create
        this.deleteObject(
          "planners/" +
            this.familyKey +
            "/meals/" +
            this.mealDate +
            "/" +
            this.meal.slug,
          this.refkey
        )
          .then(() => {
            this.createObject(
              "planners/" +
                this.familyKey +
                "/meals/" +
                planned.date +
                "/" +
                planned.meal.slug,
              planned
            )
              .then(() => {
                window.toastr.success(
                  "The planned meal has been saved successfully."
                );
              })
              .catch(error => {
                console.log(error);
                window.toastr.error(
                  "An error occurred while saving the planned meal. Please try again later."
                );
              });
          })
          .catch(error => {
            console.log(error);
            window.toastr.error(
              "An error occurred while saving the planned meal. Please try again later."
            );
          });
      }
    },
    deletePlannedMeal() {
      this.deleteObject(
        "planners/" +
          this.familyKey +
          "/meals/" +
          this.mealDate +
          "/" +
          this.meal.slug,
        this.refkey
      )
        .then(() => {
          window.toastr.success(
            "The planned meal has been deleted successfully."
          );
        })
        .catch(error => {
          console.log(error);
          window.toastr.error(
            "An error occurred while deleting the planned meal. Please try again later."
          );
        });
    },
    updateRecipe(value) {
      const recipe = { ...value };
      this.planned.recipe = recipe;
    },
    setModal() {
      this.planned = { ...this.plannedMeal };
      this.selectedMeal = this.meal.slug;
    }
  }
};
</script>

<style scoped>
.planned-meal {
  min-width: 130px;
  max-width: 280px;
  height: 40px;
  margin-left: 5px !important;
  margin-right: 5px !important;
  margin-bottom: 5px !important;
}

.planned-meal .card-img {
  height: 38px;
}

.planner-recipe-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  font-size: 95%;
  font-weight: 400;
  color: white;
  padding-top: 7px;
  text-align: left;
  padding-left: 5px !important;
}
</style>
