import { render, staticRenderFns } from "./Planners.vue?vue&type=template&id=a5cc52fe&scoped=true&"
import script from "./Planners.vue?vue&type=script&lang=js&"
export * from "./Planners.vue?vue&type=script&lang=js&"
import style0 from "./Planners.vue?vue&type=style&index=0&id=a5cc52fe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5cc52fe",
  null
  
)

export default component.exports